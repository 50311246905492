$(document).ready(function() {

    var overallWidth = $(window).outerWidth(),
        overallHeight = $(window).outerHeight(),
        sm = 576,
        md = 768,
        lg = 992,
        xl = 1200;

    $(window).resize(function() {
        overallWidth = $(window).outerWidth();
        overallHeight = $(window).outerHeight();
    });

    // MOVE HEADER ELEMENTS FOR RESPONSIVE
    function moveHeaderElements() {

        if (overallWidth >= md) {
            $(".topLinks, .topSearch").appendTo(".topBar-inner");
            $("ul.mainMenu").appendTo(".mainNav-inner");
            $(".headerTray").remove();
        } else {
            if (!$(".headerTray").length) {
                $("<div class='headerTray'></div>").insertAfter(".menuToggle");
            }
            $(".topLinks, .topSearch, ul.mainMenu").appendTo(".headerTray");
        }
    }
    moveHeaderElements();

    $(window).resize(function() {
        moveHeaderElements();
    });

    // HIDE/SHOW MOBILE NAV TRAY
    $(".menuToggle").click(function() {
        if ($(this).hasClass("on")) {
            $(this).attr("aria-expanded", false);
        } else {
            $(this).attr("aria-expanded", true);
        }
        $(this).toggleClass("on");
        $(".headerTray").slideToggle();
    });

    // HIDE/SHOW SEARCH ON DESKTOP
    $(".topSearch-toggle").click(function() {
        if ($(this).hasClass("on")) {
            $(this).attr("aria-expanded", false);
        } else {
            $(this).attr("aria-expanded", true);
        }
        $(this).toggleClass("on");
        $(".topSearch-tray").toggleClass("on");

        if (overallWidth < lg) {
            $(".topLinks").toggleClass("off");
        }
    });

    $(window).resize(function() {
        $(".topSearch-toggle").removeClass("on");
        $(".topSearch-tray").removeClass("on");
        $(".topLinks").removeClass("off");
    });

    // MAIN NAV DROP DOWNS
    $("ul.mainMenu li").each(function() {
        if ($("> ul", this).length) {
            $(this).addClass("hasChildren");
            $(this).append("<div class='dropToggle' aria-label='Show Sub Navigation'></div>");
        }
    });

    // Mobile drop down
    $("ul.mainMenu .dropToggle").click(function() {
        if ($(this).hasClass("on")) {
            $(this).removeClass("on");
            $(this).parent().find("> ul").slideUp("fast");
        } else {
            $(this).addClass("on");
            $(this).parent().find("> ul").slideDown("fast");
        }
    });

    // Desktop drop down
    $("ul.mainMenu > li").hover(function() {
            if (overallWidth >= md && $("> ul", this).length) {
                $("> ul", this).stop().slideDown("fast");
            }
        },
        function() {
            if (overallWidth >= md && $("> ul", this).length) {
                $("> ul", this).stop().slideUp("fast");
            }
        });

    // VIEW OPTIONS
    $(".viewOptions li a").click(function(e) {
        e.preventDefault();
        if ($(this).parent().hasClass("grid")) {
            $(this).closest(".viewOptions").nextAll(".hoverBox-wrapper").removeClass("list map").addClass("grid");
            $("ul.sortRow").removeClass("on");
            $(".viewOptions li a").attr("aria-selected", false);
            $(".viewOptions li.grid a").attr("aria-selected", true);
        } else if ($(this).parent().hasClass("list")) {
            $(this).closest(".viewOptions").nextAll(".hoverBox-wrapper").removeClass("grid map").addClass("list");
            $("ul.sortRow").addClass("on");
            $(".viewOptions li a").attr("aria-selected", false);
            $(".viewOptions li.list a").attr("aria-selected", true);
        } else if ($(this).parent().hasClass("map")) {
            $(this).closest(".viewOptions").nextAll(".hoverBox-wrapper").removeClass("list grid").addClass("map");
            $("ul.sortRow").removeClass("on");
            $(".viewOptions li a").attr("aria-selected", false);
            $(".viewOptions li.map a").attr("aria-selected", true);
        }
    });

    $(window).resize(function() {
        if (overallWidth < md) {
            $(".hoverBox-wrapper").removeClass("list map").addClass("grid");
        }
    });

    // SLIDER
    var slideTotal
    var currentSlide = 1;
    if ($(".sliderContainer").length) {
        $("<div class='sliderContainer-nav'><div class='sliderPrev off'></div><div class='sliderCount'>Image <span class='slider-current'>1</span> of <span class='slider-total'></span></div><div class='sliderNext'></div></div>").appendTo(".sliderContainer");

        slideTotal = $(".slide").length;

        $(".slider-total").text(slideTotal);
    }

    $(".sliderPrev").click(function() {
        $(".sliderNext").removeClass("off");
        $(".slide").first().animate({
            marginLeft: "-" + (currentSlide - 2) + "00%"
        });
        if (currentSlide <= 2) {
            $(this).addClass("off");
        }
        currentSlide--;
        $(".slider-current").text(currentSlide);
    });

    $(".sliderNext").click(function() {
        $(".sliderPrev").removeClass("off");
        $(".slide").first().animate({
            marginLeft: "-" + currentSlide + "00%"
        });
        if (currentSlide >= slideTotal - 1) {
            $(this).addClass("off");
        }
        currentSlide++;
        $(".slider-current").text(currentSlide);
    });

    // MOVE RESET ON PROPERTY SEARCH
    if ($(".propertySearch-main").length) {
        moveReset();
    }

    function moveReset() {
        if (overallWidth >= sm) {
            $(".propertySearch-actions .reset").appendTo(".propertySearch-actions");
        } else {
            $(".propertySearch-actions .reset").insertBefore(".propertySearch-actions .btn");
        }
    }

    $(window).resize(function() {
        if ($(".propertySearch-main").length) {
            moveReset();
        }
    });

    // PROPERTY SEARCH TOGGLE
    $(".propertySearch-toggle button").click(function() {
        if ($(this).parent().hasClass("on")) {
            $(this).attr("aria-expanded", false);
        } else {
            $(this).attr("aria-expanded", true);
        }
        $(this).parent().toggleClass("on");
        $(".propertySearch-tray").toggleClass("on");
    });

    // MOVE SIDEBARS ON GENERAL SUB
    if ($(".sidebar").length) {
        var bodyCount = $(".sidebar").length;
        var currentBody = 1;
        var currentSidebar = 1;

        $(".generalBody").each(function() {
            $(this).attr("id", "body" + currentBody);
            currentBody++;
        });

        $(".sidebar").each(function() {
            $(this).attr("id", "sidebar" + currentSidebar);
            currentSidebar++;
        });
    }

    function moveSidebars() {

        currentBody = 1;
        currentSidebar = 1;

        if (overallWidth >= md) {

            $(".sidebar").each(function() {
                if (currentSidebar === 1) {
                    $("#sidebar1").appendTo("#body1 .row");
                } else if (currentSidebar === 2) {
                    $("#sidebar2").appendTo("#body2 .row");
                }
            });

        } else {

            $(".sidebar").each(function() {
                $(this).insertBefore("footer");
            });

        }
    }

    moveSidebars();

    $(window).resize(function() {
        moveSidebars();
    });

    // PROPERTY DETAIL TABS
    $(".detailTabs li a").click(function(e) {
        e.preventDefault();
        var thisIndex = $(this).parent().index();

        $(".detailTabs li").removeClass("on");
        $(this).parent().addClass("on");

        $(".detailTabContent").removeClass("on");
        $(".detailTabContent").eq(thisIndex).addClass("on");
    });

    // SORT PROPERTY SEARCH LIST
    $(".sortRow li").on('click', "a", function(e) {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var direction;


        if ($(this).parent().hasClass("down")) {
            $(this).parent().removeClass("down").addClass("up");
            direction = "up";
        } else if ($(this).parent().hasClass("up")) {
            $(this).parent().removeClass("up").addClass("down");
            direction = "down";
        } else {
            $(".sortRow li").removeClass("down up");
            $(this).parent().addClass("down");
            direction = "down";
        }

        var mylist = $('.hoverBox-wrapper');
        var listitems = mylist.children('.hoverBox-outer').get();

        if ($(this).parent().hasClass("property")) {
            listitems.sort(function(a, b) {
                var compA = $(a).data("property");
                var compB = $(b).data("property");
                if (direction === "up") {
                    return (compA > compB) ? -1 : (compA < compB) ? 1 : 0;
                } else if (direction === "down") {
                    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
                }
            })
        } else if ($(this).parent().hasClass("contact")) {
            listitems.sort(function(a, b) {
                var compA = $(a).data("contact");
                var compB = $(b).data("contact");
                if (direction === "up") {
                    return (compA > compB) ? -1 : (compA < compB) ? 1 : 0;
                } else if (direction === "down") {
                    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
                }
            })
        } else if ($(this).parent().hasClass("gla")) {
            listitems.sort(function(a, b) {
                var compA = $(a).data("gla");
                var compB = $(b).data("gla");
                if (direction === "up") {
                    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
                } else if (direction === "down") {
                    return (compA > compB) ? -1 : (compA < compB) ? 1 : 0;
                }
            })
        } else if ($(this).parent().hasClass("availability")) {
            listitems.sort(function(a, b) {
                var compA = $(a).data("availability");
                var compB = $(b).data("availability");
                if (direction === "up") {
                    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
                } else if (direction === "down") {
                    return (compA > compB) ? -1 : (compA < compB) ? 1 : 0;
                }
            })
        }

        $.each(listitems, function(idx, itm) {
            mylist.append(itm);
        });
    });

    // IE SVG ANIMATION
    // IF THE BROWSER IS INTERNET EXPLORER 11
    if ($(".homeHero").length) {
        var UAString = navigator.userAgent;
        if (UAString.indexOf("Trident") !== -1 && UAString.indexOf("rv:11") !== -1) {
            var p = document.querySelector('#Stroke-1'),
                offset = 750;

            var offsetMe = function() {
                //if (offset < 0) offset = 750;
                p.style.strokeDashoffset = offset;
                offset = offset - 15;
                p.style.strokeWidth = 10;

                $("#Stroke-1").animate({
                    opacity: 1
                });

                if (offset > 0) {
                    requestAnimationFrame(offsetMe);
                }
            }

            offsetMe();

            var p2 = document.querySelector('#Stroke-2'),
                offset2 = 760;

            var offsetMe2 = function() {
                //if (offset < 0) offset = 750;
                p2.style.strokeDashoffset = offset2;
                offset2 = offset2 - 15;
                p2.style.strokeWidth = 10;

                $("#Stroke-2").animate({
                    opacity: 1
                });

                if (offset2 > 0) {
                    requestAnimationFrame(offsetMe2);
                }
            }

            offsetMe2();

            var p3 = document.querySelector('#Stroke-3'),
                offset3 = 132;

            var offsetMe3 = function() {
                //if (offset < 0) offset = 750;
                p3.style.strokeDashoffset = offset3;
                offset3 = offset3 - 2;
                p3.style.strokeWidth = 10;

                $("#Stroke-3").animate({
                    opacity: 1
                });

                if (offset3 > 0) {
                    requestAnimationFrame(offsetMe3);
                }
            }

            offsetMe3();

            var p4 = document.querySelector('#Stroke-4'),
                offset4 = 139;

            var offsetMe4 = function() {
                //if (offset < 0) offset = 750;
                p4.style.strokeDashoffset = offset4;
                offset4 = offset4 - 2;
                p4.style.strokeWidth = 10;

                $("#Stroke-4").animate({
                    opacity: 1
                });

                if (offset4 > 0) {
                    requestAnimationFrame(offsetMe4);
                }
            }

            offsetMe4();
        }
    }

});